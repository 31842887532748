footer
{
    display: flex;
    color: #fff;
    justify-content: space-around;
    width: 90%;
    margin: 0 auto;
    padding:160px 0 80px 0;

     > div:nth-child(1)
     {
        width: 60%;
     }

    .footer-logo
    {
        img
        {
            max-width: 100%;
            width: 350px;
        }
    }
    .footer-addressinfo
    {
        margin-left: 100px;
    }
    .right-item
    {
        width: 40%;
        display: flex;
        justify-content:space-around;
        margin-left: auto;
        ul
        {
            // flex-basis:calc((100% - 40px) / 3);
            // max-width:calc((100% - 40px) / 3);        

            li
            {
                margin-bottom: 10px;
            }
        }
    }
}

@media (max-width: 768px) 
{
    footer
    {
        flex-direction: column;
        padding: 100px 0;
        .footer-addressinfo
        {
            margin-left: 0;
            margin-bottom: 10%;
        }

        .footer-item
        {
            flex-direction: column;
        }

        .right-item
        {
            width: 100%;

            ul
            {
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                li
                {
                    font-size: 1.5rem;
                    margin-right: 10%;

                }
            }
        }
    }
}