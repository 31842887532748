
@import 'top';
@import 'common';
@import 'form';
@import 'footer';


$acs_color : #99E6F5;

// .mainVisual {
//     position: relative;
//     width: 100%;
//     height: 300px;
//     overflow: hidden;
//   }
//   .mainVisual  video {
//     position: absolute;
//     left: 0;
//     top: 0;
//     min-width: 100%;
//     min-height: 100%;
//     z-index: -1;
//   }

// .video-wrap
// {
//     padding-top: 65px;
//     position: relative;
//     min-width: 100%;
//     min-height: 100%;
//     width: auto;
//     height: auto;
//     z-index: -100;
//     overflow: hidden;
//     .movie-caption    
//     {
//         font-family: serif;          
//         color: #fff;
//         font-size: 400%;
//         position: absolute;
//         top: 50%;
//         left: 50%;
//         transform: translateX(-50%) translateY(-50%);
//         z-index: 1;
//     }
// }

.hero 
{
    position: relative;

    .video-box 
    {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 100vh;

        .video 
        {
            position: absolute;
            top: 50%;
            left: 50%;
            min-width: 100%;
            min-height: 100%;
            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
        }
  
        .overlay::after 
        {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            content: "";
            background: rgba(0, 0, 0, 0.4);
        }
    }

    .text-box 
    {
        position: absolute;
        top: 84%;
        right: 0;
        width: 100%;
    }
    h1 
    {
        // font-family: Roboto;
        font-size: 60px;
        font-weight: bold;
        line-height: 1.2;
        text-align: right;
        color: #fff;

        br
        {
            display: none;
        }
    }
}



.news-flex
{
    position: relative;
    background-color: #FFFFFF;
    margin: 0 auto;
    width: 70%;
    padding: 2%;
    margin-bottom: 120px;
    .news-style
    {
        position: absolute;
        top: 0;
        left: 0;
        background-color: #2E2E2E;
        width: 10%;
        height: 100%;
    }

    ul
    {
        background-color: #FFFFFF;
        width: auto;
        margin-left: 20%;
        margin-right: auto;
        li
        {
            list-style: none;
            margin: 2% 0;
        }

        li span.category
        {
            background-color: $acs_color;
            color: #fff;
            padding: 1%;
            font-size: 1em;
            margin-right: 2%;
        }
    }

    .more-btn
    {
        background-color: #000000;
        color: #FFFFFF;
        margin-right: 0;
        margin-left: auto;
        width: 100px;
        text-align: center;
        border-radius: 25px;
        padding: 5px 35px;
        font-size: 1.4rem;
    }
}

.bussiness-flex
{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto;
    font-weight: bold;
    a
    {
        position: relative;
        width: 50%;
        background-color: #2CAF63;
        flex-basis:calc((100% - 20px) / 2);
        max-width:calc((100% - 20px) / 2);
        margin-top:20px;
        display: block;
        .bussiness-rel
        {
            .bussiness-img
            {
                display: block;
                width: 100%;
                object-fit: cover;
                height: 300px;
            }
    
            .bussiness-abs
            {
                position: absolute;
                top: 0;
                left: 0;
                background-color: rgba(0,0,0,.5);
                font-size: 3.2rem;
                height: 100%;
                text-align: center;
                width: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .bussiness-style-borde
            {
                background-color: $acs_color;
                width: 2px;
                height: 100%;
                z-index: 99;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }
}

h1.shippori-mincho-regular 
{
    font-family: "Shippori Mincho", serif;
    font-weight: 700;
    font-style: bold;
}
  

.container
{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 80%;
    margin: 0 auto;
    a
    {
        flex:0 calc(33.33% - 20px) !important;
        box-sizing: border-box;
        position: relative;
        overflow: hidden;
        height: 250px;
        width:100%;
        .item
        {
            img
            {
                width: 100%;
                height: 100%;
                display: block;
                object-fit: cover;
            }
    
            img.loaded 
            {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
            }
    
            .works-abs
            {
                position: absolute;
                bottom: 0;
                left: 0;
                color: #FFFFFF;
                width: 100%;
                display: flex;
                .abs-style
                {
                    background-color: #000000;
                    width: 8%;
                }
    
                .abs-text
                {
                    width:100%;
                    font-size: 1.8rem;
                    background-color: #2E2E2E;
                }
            }
        }   
    }

    .item::before 
    {
        content: "";
        display: block;
        padding-top: 100%;
    }
}

.back-btn-box
{
    margin: 0 auto;
    width: 80%;
    .back-btn 
    {
        width: 15%;
        text-align: center;
        margin: 0 auto;
        background-color: #000;
        color: #FFF;
        padding: 1% 2%;
        margin-top: 5%;
        border-radius: 30px;

    }
}



.works-rel:nth-of-type(3n-1):nth-last-of-type(1)
{
    margin-right:auto;
    margin-left:20px;
}

.flow-flex
{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 80%;
    margin: 0 auto;

    .flow-item
    {
        img
        {
            width: 100%;
        }
        // flex-basis: 30%;
    }

    .triangle-posi
    {
        width: 0;
        height: 0;
        border-top: 30px solid transparent;
        border-bottom: 30px solid transparent;
        border-left: 30px solid black;
        margin: 0 auto;
    }
}

.map-flex
{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 90px;
    margin: 0 auto;
    width: 80%;
    margin-bottom: 8%;
    // }
    
    .map-left
    {
        width: 50%;
    }

    .map-wrap
    {
        width: 100%;
        height: 400px; 
        text-align: right;
    }
}

@media (max-width: 1300px) 
{
    .map-flex
    {
        width: 90%;
        flex-direction: column-reverse;

        .map-left 
        {
            margin-top: 5%;
        }

        .map-wrap
        {
            text-align: center;
        }
    }
}

@media (max-width: 768px) 
{
    div.header
    {
        display: none !important;
    }   

    header
    {
        display: block;
        position: fixed;
        z-index: 999;
        background-color: #000000;
        width: 100%;
        .header-logo 
        {
            margin-left: 5px;
            margin-top: 3px;
            img
            {
                width: 100%;
                max-width: 200px;
            }
        }
    }
    .news-flex
    {
        width: 80%;
        padding: 50px 0;
        .more-btn
        {
            margin: 0 auto;
            margin-top: 30px;
        }
    }

    .bussiness-flex
    {
        flex-direction: column;

        a
        {
            width: 100%;
            max-width: none;
            .bussiness-rel
            {
                .bussiness-img
                {
                    height: 200px;
                }
                .bussiness-abs
                {
                    font-size: 1.8rem;
                }
            }
        }
    }

    .container
    {
        flex-direction: column;
        width: 80%;
        .item
        {
            flex: none;
        }
    }

    .back-btn-box
    {
        
        .back-btn 
        {
            width: 80%;
            padding: 5% 3%;
            margin-top: 12%;
        }
    }

    .form-bg
    {
        .form-style-border
        {
            transform: rotate(90deg);
            margin-right: 0!important;
            margin-top: -25%;
            margin-bottom: -20%;
        }
        .form-flex
        {
            flex-direction: column;
    
            .form-left 
            {
                width: 100%;
            }
    
            .form-right
            {
                width: 100%;

                .form-tel-flex
                {
                    flex-direction: column;

                    .tel-box
                    {
                        flex-direction: column;
                    }
                }

                .form-button
                {
                    padding: 25px;
                }
            }
        }
    }

    .flow-flex
    {
        flex-direction: column;

        .triangle-posi
        {
            border-left: 30px solid transparent;
            border-right: 30px solid transparent;
            border-top: 30px solid black;
            margin: 0 auto;
            margin-top: 30px;
        }
    }

    .map-flex
    {
        width: 90%;
        flex-direction: column-reverse;
        .map-left
        {
          width: 100%;
          padding: 20px;
          margin-top: -10%;
        }
    
        .map-wrap 
        {
            width: 100%;
          iframe
          {
            width: 100%;
            height: 300px;
          }
        }
    }

    .text-box
    {
        h1
        {
            font-size: 40px;
            br
            {
                display: block;
            }
        }
    }
}