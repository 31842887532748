main
{
    padding-top: 50px !important;
}



a
{
    color:inherit;
    text-decoration: none
}


section
{
    color: #FFFFFF;
    margin: 0 auto;
    margin: 0 auto;
    padding: 120px 0;
    .section-title
    {
        width: 100%;
        margin: 0 auto;
        text-align: center;
        margin-bottom: 40px;

        .title-jp
        {
            font-size: 4.8rem;
            font-weight: bold;
            margin-bottom: 10px;
        }
        .title-en
        {
            font-size: 1.6rem;
            font-weight: bold;
            letter-spacing: 0.5em;
        }
    }

    .section-abst
    {
        text-align: center;
        margin: 0 auto;
        margin-bottom: 55px;
    }
}

section.lite
{
    background-color: #FFFFFF;
    color: #000000;
    width: 100%;
}

section.gray
{
    background-color: #cccccc;
    color: #000000;
}

section.white-gray
{
    background: linear-gradient(180deg,#f2f2f2 0,#f2f2f2 50%,#D9D9D9 50%,#D9D9D9 100%);
    color: #000000;
}

section.form
{
    background-color: #707070;
    width: 100%;
    padding:20px 0;
    font-weight: bold;
}

section.gray-sec
{
    background: #f2f2f2;
    color: #000000;
}


@media (max-width: 768px) 
{
    section   
    {
        padding: 80px 0;

        .section-title
        {
            .title-jp
            {
                font-size: 4.2rem!important;
            }
            .title-en
            {
                margin-top: 2.5%;
            }
        }

        .section-abst
        {
            width: 90%;
        }
    }
}