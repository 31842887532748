$acs_color : #99E6F5;

.form-bg
{
    background-color: #000000;
    
    .form-flex
    {
        display: flex;
        margin: 0 auto;
        padding: 2% ;
        width: 80%;
        color: #FFFFFF;
        justify-content:center;
        align-items: center;

        .form-left
        {
            width: 30%;

            .title-jp{font-size: 5.2rem;}

            .title-en
            {
                font-size: 1.6rem;
                font-weight: bold;
                letter-spacing: 0.5em;
            }
        }

        .form-style-border
        {
            width: 2px;
            height: 300px;
            margin-right: 85px;
            background-color: $acs_color;
        }

        .form-right
        {
            width: 70%;

            .form-button
            {
                background-color: #2CAF63;
                color: #FFFFFF;
                font-size: 3.0rem;
                text-align: center;
                padding: 35px 50px;
                margin: 0 auto;
                margin-bottom: 40px;
            }   

            .form-button::before
            {
                content: "";
                display: inline-block;
                width: 35px;
                height: 35px;
                background: url(./img/mail.png) no-repeat;
                background-size: contain;
                margin-right: 8px;
                margin-bottom: -9px;
            }
            .form-tel-flex
            {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .tel-box
                {
                    display: flex;
                    justify-content: space-between;
                    .tel-item
                    {
                        // flex-basis:calc((100% - 20px) / 2);
                        // max-width:calc((100% - 20px) / 2);
                        width: 100%;
                        margin-right: 40px;
                        div:nth-child(1)
                        {
                            margin-left: 17%;
                        }

                        .tel-text
                        {
                            font-size: 3.2rem;
                        }
                        .tel-text::before
                        {
                            content: "";
                            display: inline-block;
                            width: 30px;
                            height: 30px;
                            background: url(img/tel.png) no-repeat;
                            background-size: contain;
                            margin-right: 8px;
                            background-position-y: 3px;
                        }
                    }
                }

                .question-button::before
                {
                    content: "";
                    display: inline-block;
                    width: 35px;
                    height: 35px;
                    background: url(./img/qamark.png) no-repeat;
                    background-size: contain;
                    margin-right: 8px;
                    margin-bottom: -9px;
                }
            }

            .form-bottom-tel
            {
                font-size: 2.2rem;
                margin: 2% auto;
                text-align: center;

                br
                {
                    display: none;
                }
            }


        }
    }
}

@media (max-width: 768px) 
{
    .form-bg
    {
        .form-style-border
        {
            transform: rotate(90deg);
            margin-right: 0!important;
            margin-bottom: -30%;
        }
        .form-flex
        {
            flex-direction: column;
            padding-bottom: 15%;
            padding-top: 15%;
            .form-left 
            {
                width: 100%;

                .title-jp
                {
                    font-size: 4.2rem;
                }
            }
    
            .form-right
            {
                width: 100%;

                .form-tel-flex
                {
                    flex-direction: column;

                    .tel-box
                    {
                        flex-direction: column;
                    }
                }

                .form-button
                {
                    padding: 25px;
                    font-size: 2.0rem;
                }

                .form-bottom-tel
                {
                    margin-top: 5%;
                    font-size: 1.7rem;
                }
            }
        }
    }
}