html
{
    // color: aqua;
    background-color:rgb(48,48,48);
    font-size: 62.5%;
    font-family: '小塚ゴシック Pro','Kozuka Gothic Pro',sans-serif;
}

body
{
    margin: 0;
    padding: 0;
}


header
{
    display: none;
    // background-color: #000000;
    // height: 60px;
    // position: fixed;
    // z-index: 999;
    // .header-logo
    // {
    //     img
    //     {
    //         max-width: 200px;
    //         width: 100%;
    //         padding-left: 20px;
    //     }
    // }
}
div.header
{
    display:  flex;
    position: fixed;
    height: 65px;
    width: 100%;
    color: #FFFFFF;
    z-index: 999;
    background-color: #000000; 
    .head-left
    {
        width: 30%;
        padding-left: 20px;
        padding-top: 5px;
        img
        {
            max-width: 100%;
            width: 200px;
        }
    }
    .head-right
    {
        width: 70%;
        display: flex;
        justify-content:flex-end;
        nav
        {
            margin-right: 10%;
            width: 460px;
            max-width: 100%;
            ul
            {
                display: flex;
                align-items: center;
                a
                {
                    display: block;
                    list-style-type: none;
                    padding: 2%;
                    text-align: center;
                    margin: 0 auto;
                    li
                    {
                        img
                        {
                            width: 35px;
                            height: 35px;
                            max-width: 100%;
                        }
                    }

                    li.button-li
                    {
                        margin-top: 20%;
                    }
                }
            }
        }
    
    
        .form-button
        {
            background-color: #2CAF63;
            width: 200px;
            height: 65px;
            line-height: 65px;
            text-align: center;
        }
    }
}

.drawer-hamburger-icon, 
.drawer-hamburger-icon:after, 
.drawer-hamburger-icon:before 
{
    background: #FFFFFF;
    height: 3px;
}

@media (max-width: 768px) 
{
    header   
    {
        display: flex!important;
        justify-content: space-between;
        align-items: center;

        .flex-display
        {
            margin-right: 10%;
            display: block;
            img
            {
                max-width: 100%;
                width: 40px;
                height: 40px;
            }
        }
    }
}